<template>
  <div>
    <b-card>
      <ValidationObserver ref="termsAndCondition" v-slot="{ handleSubmit }">
        <b-form class="form-top p-4" @submit.prevent="handleSubmit(formSubmit)">
        <b-row>
          <b-col md="6">
            <input-form v-model="termsAndCondition.terms_and_conditions.translations[0].heading" :name="$t('global.headingAr')" :label="$t('global.headingAr')" validate="required" :disabled="disableStatus" />
          </b-col>
          <b-col md="6">
            <input-form v-model="termsAndCondition.terms_and_conditions.translations[1].heading" :name="$t('global.headingEn')" :label="$t('global.headingEn')" validate="required" :disabled="disableStatus" />
          </b-col>

          <b-col md="6">
            <textarea-form v-model="termsAndCondition.terms_and_conditions.translations[0].content" :name="$t('global.contentAr')" :label="$t('global.contentAr')" validate="required" :disabled="disableStatus" />
          </b-col>
          <b-col md="6">
            <textarea-form v-model="termsAndCondition.terms_and_conditions.translations[1].content" :name="$t('global.contentEn')" :label="$t('global.contentEn')" validate="required" :disabled="disableStatus" />
          </b-col>
        </b-row>

        <form-btns v-if="currentPage != itemPages.view" :label="submitLabel" :loading="loading" />
      </b-form>
      </ValidationObserver>
    </b-card>
  </div>
</template>
<script>
import { core } from '../../../../config/pluginInit'
import termsAndConditionServices from '../services/termsAndConditions'
import TermsAndCondition from '../models/TermsAndCondition'
import moduleItemMixin from '../../../../Mixins/moduleItemMixin'
export default {
  mixins: [moduleItemMixin],
  data () {
    return {
      termsAndCondition: new TermsAndCondition()
    }
  },
  methods: {
    async create () {
      termsAndConditionServices.create(this.termsAndCondition).then(response => {
        this.loading = false
        core.showSnackbar('success', response.data.message)
        this.lastRoute.name ? this.$router.push(this.lastRoute) : this.$router.push({ name: 'generalSettings.termsAndConditions' })
      }).catch(() => {
        this.loading = false
      })
    },
    async update () {
      termsAndConditionServices.update(this.id, this.termsAndCondition).then(response => {
        this.loading = false
        core.showSnackbar('success', response.data.message)
        this.lastRoute.name ? this.$router.push(this.lastRoute) : this.$router.push({ name: 'generalSettings.termsAndConditions' })
      }).catch(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    core.index()
  },
  created () {
    if (this.id) {
      termsAndConditionServices.findOne(this.id).then(response => {
        this.termsAndCondition.fillData(response.data.data)
        this.loading = false
      })
    } else {
      this.loading = false
    }
  }
}
</script>
