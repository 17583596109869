export default class TermsAndCondition {
  constructor () {
    this.setInitialValue()
  }

  setInitialValue () {
    this.terms_and_conditions = {
      translations: [
        { id: null, heading: '', content: '', lang: 'ar' },
        { id: null, heading: '', content: '', lang: 'en' }
      ]
    }
  }

  fillData (data) {
    if (data) {
      const itemAr = data.translations?.find(o => o.lang === 'ar')
      const itemEn = data.translations?.find(o => o.lang === 'en')

      this.terms_and_conditions.translations[0].id = itemAr ? itemAr.id : null
      this.terms_and_conditions.translations[0].heading = itemAr ? itemAr.heading : ''
      this.terms_and_conditions.translations[0].content = itemAr ? itemAr.content : ''
      this.terms_and_conditions.translations[1].id = itemEn ? itemEn.id : null
      this.terms_and_conditions.translations[1].heading = itemEn ? itemEn.heading : ''
      this.terms_and_conditions.translations[1].content = itemEn ? itemEn.content : ''
    } else {
      this.setInitialValue()
    }
  }
}
